var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "훈련 시나리오 목록",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            isExpand: true,
            usePaging: false,
            columnSetting: false,
            rowKey: "trainingScenarioNo",
            selection: _vm.popupParam.type,
          },
          on: { rowDblclick: _vm.rowDblclick },
          scopedSlots: _vm._u([
            {
              key: "expand-header",
              fn: function () {
                return [_vm._v(" 훈련 시나리오 상세 ")]
              },
              proxy: true,
            },
            {
              key: "expand-td",
              fn: function (props) {
                return _vm._l(
                  props.row.emergencyScenarioDetailModelList,
                  function (item, index) {
                    return _c(
                      "c-card",
                      { key: index, attrs: { noHeader: true } },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "소요시간",
                                      name: "leadTime",
                                    },
                                    model: {
                                      value: item.leadTime,
                                      callback: function ($$v) {
                                        _vm.$set(item, "leadTime", $$v)
                                      },
                                      expression: "item.leadTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "LBLITEMS",
                                      name: "itemName",
                                    },
                                    model: {
                                      value: item.itemName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "itemName", $$v)
                                      },
                                      expression: "item.itemName",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-4" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "상세훈련내용",
                                      name: "trainingDetailContents",
                                    },
                                    model: {
                                      value: item.trainingDetailContents,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "trainingDetailContents",
                                          $$v
                                        )
                                      },
                                      expression: "item.trainingDetailContents",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-2" },
                                [
                                  _c("c-text", {
                                    attrs: {
                                      disabled: true,
                                      editable: _vm.editable,
                                      label: "LBLMANAGER",
                                      name: "managerUserName",
                                    },
                                    model: {
                                      value: item.managerUserName,
                                      callback: function ($$v) {
                                        _vm.$set(item, "managerUserName", $$v)
                                      },
                                      expression: "item.managerUserName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    )
                  }
                )
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "LBLSEARCH", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                  _c("c-btn", {
                    attrs: { label: "LBLSELECT", icon: "check" },
                    on: { btnClicked: _vm.select },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }